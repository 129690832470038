// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("/opt/build/repo/src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-signup-js": () => import("/opt/build/repo/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-of-service-js": () => import("/opt/build/repo/src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-use-cases-js": () => import("/opt/build/repo/src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */)
}

